
import { mdiHelpCircleOutline } from '@mdi/js'

export default {
  name: 'HelpTooltip',
  props: {
    size: {
      type: [Number, String],
      default: undefined,
    },
  },
  data: () => ({
    mdiHelpCircleOutline,
  }),
}
